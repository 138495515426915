import { FC, useState } from 'react';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import InfoIcon from 'assets/icons/InfoIcon';
import { RootStateOrAny, useSelector } from 'react-redux';
import { AuthUpdateBannerContainer } from './styled';

interface Props{
  content: string;
}

const NotificationBanner: FC<Props> = ({content}) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);

  const {
    loading: appLoading,
  }: {
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  return !appLoading && showBanner ? (
    <Notification
      noShadow
      centeredButton
      hasClosed
      closeHandler={() => setShowBanner(false)}
      backgroundColor={themeColors.bg.errorMuted}
      color={themeColors.text.primary}
      icon={<InfoIcon />}
      descriptionJSX={
        <AuthUpdateBannerContainer>
          <p><strong>Important Notice</strong></p>
          <p>
            If your company relies on a logistics service provider (LSP) to receive invoices and logistics data, please be aware of a technical issue causing API
            errors. This is currently preventing data transmission between Kanexa and the LSP. If specific invoices or FCR data are missing from the vendor platform, this issue may be the underlying cause.
            Our technical teams are working diligently to resolve the problem and ensure any delayed data is processed as soon as possible. Specific inquiries should be directed to <a href="mailto:support@kanexa.com">support@kanexa.com</a>. We appreciate your understanding and patience as we work toward a resolution!
          </p>
          <p>Kanexa Support Team</p>
        </AuthUpdateBannerContainer>
      }
    />
  ) : (
    <></>
  );
};

export default NotificationBanner;
